



































import { defineComponent, reactive, ref } from '@vue/composition-api';
import { InputBox } from '@/components';

import { RountName } from '@/router';
import { authSearvice } from '@/services';

import { AccountStore } from '@/store/modules';

export default defineComponent({
  name: 'AuthLogin',
  components: {
    InputBox,
  },
  setup(_, { root }) {
    const isLoading = ref(false);
    const form = reactive({
      password: '',
      rePassword: '',
    });

    async function onSubmitClicked() {
      if (
        !form.password ||
        form.password.length < 8 ||
        !form.rePassword ||
        form.rePassword.length < 8
      ) {
        alert('비밀번호를 입력해 주세요.');
        return;
      }
      if (form.password !== form.rePassword) {
        alert('비밀번호를 확인해 주세요.');
        return;
      }

      if (isLoading.value) return;

      isLoading.value = true;

      try {
        await authSearvice.updatePassword(form.password);

        alert('비밀번호가 변경되었습니다.');

        AccountStore.logout();

        root.$router.push({
          name: RountName.AuthLogin,
        });
      } catch (e) {
        //
      } finally {
        isLoading.value = false;
      }
    }

    return {
      RountName,

      form,

      onSubmitClicked,
    };
  },
});
